import React from 'react';
import {Row} from 'react-bootstrap';
import DashboardItem from './DashboardItem';
import { faCommentDots, faEnvelope, faLock, faUser, faUmbrella, faReceipt } from '@fortawesome/free-solid-svg-icons';

const AccountDashboard = () => {

    const list = [
        /*{
            title: 'Biroul de credit', 
            info: 'Vezi cu ce informații figurezi în baza de date de la Biroul de Credit.',
            link: '/user/biroul-de-credit', 
            thumb: '/images/icons/aplica-icon.png',
            width: 70,
            height: 70,
            type: 'image',
        },*/
        {
            title: 'Cereri de credit', 
            info: 'Aplicațiile de credit, completate pe Conso și transmise către partenerii noștri.',
            link: '/user/credite/cereri', 
            thumb: '/images/icons/personal-loans-icon.png',
            width: 60,
            height: 60,
            type: 'image',
        },
        {
            title: 'Asigurări RCA', 
            info: 'Polițele RCA, achiziționate pe Conso. Ai acces oricând la polița în format electronic.',
            link: '/user/asigurari/rca', 
            thumb: '/images/icons/credite-auto-icon.png',
            width: 100,
            height: 60,
            type: 'image',
        },
        {
            title: 'Oferte RCA', 
            info: 'Ofertele salvate pentru asigurări RCA. Adaugă în cos și finalizează comanda.',
            link: '/user/asigurari/rca/oferte', 
            thumb: faUmbrella,
            type: 'icon',
        },
        {
            title: 'Comenzi', 
            info: 'Cumparaturile online efectuate pe Conso.',
            link: '/user/comenzi', 
            thumb: faReceipt,
            type: 'icon',
        },
        {
            title: 'Recenzii', 
            info: 'Recenziile postate la Vocea Clientului, despre experințele avute cu produsele financiare.',
            link: '/user/recenzii', 
            thumb: faCommentDots,
            type: 'icon',
        },
        {
            title: 'Newsletter', 
            info: 'Newsletterele la care ești abonat.',
            link: '/user/profil/newsletter', 
            thumb: faEnvelope,
            type: 'icon',
        },
        {
            title: 'Date personale', 
            info: 'Datele personale salvate în cont.',
            link: '/user/profil/info', 
            thumb: faUser,
            type: 'icon',
        },
        {
            title: 'Schimbare Parolă', 
            info: 'Modificarea parolei de acces.',
            link: '/user/profil/info', 
            thumb: faLock,
            type: 'icon',
        },
    ];

    return (
        <Row className="p-3 d-flex justify-content-between">
            {list.map( (item, i)=>(
                <DashboardItem 
                    key={i}
                    details={item}
                />
            ))}
        </Row>
    );
}

export default AccountDashboard;